type DateTime = string;

export type Nullable<T> = T | null;

export type SelectOption = {
  value: string | number;
  label: string;
  disabled?: boolean;
  badge?: string;
  badgeColor?: string;
};

export type TabNavItem = {
  href: string;
  label: string;
  active?: boolean;
  disabled?: boolean;
  tooltip?: string;
  subitems?: TabNavItem[];
};

export enum EFeature {
  Variants = 'variants',
}

export enum EFormatAccess {
  Open = 'open',
  Restricted = 'restricted',
  Closed = 'closed',
}

export enum ESharePermission {
  Allow = 'allow',
  Deny = 'deny',
}

export enum ERibbonMode {
  Category = 'category',
  Manual = 'manual',
}

export enum ERibbonType {
  Portrait = 'portrait',
  Landscape = 'landscape',
  Spotlight = 'spotlight',
  Feature = 'feature',
}

export enum ERibbonSize {
  Normal = 'normal',
  Large = 'large',
}

export enum EFormatStatus {
  Draft = 'draft',
  Review = 'review',
  Published = 'published',
}

export enum EFormatState {
  Unknown = 'unknown',
  PaperFormat = 'paper_format',
  Commissioned = 'commissioned',
  Produced = 'produced',
}

export enum EProducedBy {
  Company = 'company',
  Users = 'users',
  Other = 'other',
}

export enum EChatThreadType {
  Default = 'default',
  Offer = 'offer',
  Meeting = 'meeting',
  Auction = 'auction',
}

export enum ETeamType {
  Normal = 'normal',
  Network = 'network',
  Broadcaster = 'broadcaster',
}

export enum ERecipientType {
  Auction = 'auction',
  Meeting = 'meeting',
  Option = 'option',
}

export interface Fact {
  key: string;
  value: string;
}

interface BaseModel {
  id: number;
  created_at: DateTime;
  updated_at: DateTime;
  deleted_at?: DateTime;
}

export interface Team extends BaseModel {
  name: string;
  slug: string;
  logo_url?: string;
  type: ETeamType;
  has_networks: boolean;
}

export interface Network extends BaseModel {
  name: string;
}

export interface Invitation extends BaseModel {
  format_id?: number;
  email: string;
  role?: string;
}

export interface User extends BaseModel {
  first_name: string;
  last_name: string;
  name: string;
  company: string;
  email: string;
  current_team_id: Nullable<number>;
  current_team_role: Nullable<string>;
  profile_photo_path: Nullable<string>;
  profile_photo_url: string;
  two_factor_enabled: boolean;
  email_verified_at: Nullable<DateTime>;
}

export interface Ribbon extends BaseModel {
  mode: ERibbonMode;
  title?: string;
  type: ERibbonType;
  size: ERibbonSize;
  is_active: boolean;
  category?: Category;
  formats?: Format[];
  sort_order: number;
  is_first: boolean;
  is_last: boolean;
}

export interface Category extends BaseModel {
  name: string;
  slug: string;
  formats?: Format[];
  formats_count?: number;
}

export interface Country extends BaseModel {
  name: string;
  country_code: string;
  languge_code: string;
}

export interface Recipient extends BaseModel {
  name: string;
  email: string;
  type: ERecipientType;
}

export interface FormatSettings extends BaseModel {
  format: Format;
  can_book_meeting: boolean;
  meeting_recipients: Recipient[];
  can_buy_option: boolean;
  option_price: number;
  option_terms: string;
  option_recipients: Recipient[];
  can_send_message: boolean;
  is_sensitive: boolean;
  has_auction: boolean;
  auction_ends_at: DateTime | null;
  auction_minimum_price: number;
  has_auction_option: boolean;
  auction_option_price: number;
  ends_at: DateTime | null;
}

export interface FormatVariant extends BaseModel {
  variant_name: string;
  title: string;
  status: EFormatStatus;
  feature_image_url: string;
  portrait_image_url: string;
  landscape_image_url: string;
  video_url?: string;
  logo_url?: string;
  variants?: FormatVariant[];
}

export interface Format extends BaseModel {
  uuid: string;
  parent_id?: number;
  variant_name?: string;
  title: string;
  slug: string;
  status: EFormatStatus;
  access: EFormatAccess;
  teaser?: string;
  description?: string;
  produced_by?: EProducedBy;
  produced_by_other?: string;
  thumbnail_url?: string;
  feature_image_url?: string;
  portrait_image_url?: string;
  landscape_image_url?: string;
  logo_url?: string;
  video_url?: string;
  has_file: boolean;
  file_name?: string;
  legal?: string;
  state: EFormatState;
  completion: number;
  countries?: string;
  seasons?: string;
  years?: string;
  track_record?: string;
  facts?: Fact[];
  is_variant: boolean;
  settings: FormatSettings;
  images?: Image[];
  team?: Team;
  user?: User;
  parent?: FormatVariant;
  variants?: FormatVariant[];
  variant_count?: number;
  visit_count?: number;
  pageview_count?: number;
  favorite_count?: number;
  producers: User[];
  tags: Tag[];
  categories: Category[];
  broadcasterCategories?: Category[];
  shares?: FormatShare[];
}

export interface FormatShare {
  entity_id: number;
  entity_type: string;
  entity: Team | Network | User;
  permission: string;
}

export interface Image extends BaseModel {
  image_url: string;
  image_preview_url: string;
  meta: any;
}

export interface Tag extends BaseModel {
  name: string;
  slug: string;
  formats_count?: number;
}

export interface Favorite extends BaseModel {
  team_id: number;
  user_id: number;
}

export interface ChatThread extends BaseModel {
  team_id: number;
  created_by: number;
  author: User;
  type: EChatThreadType;
  messages: ChatMessage[];
}

export interface ChatMessage extends BaseModel {
  chat_id: number;
  user: User;
  message: string;
}

export interface Auth {
  user: Nullable<
    User & {
      all_teams: Team[];
      current_team: Team;
      role?: string;
    }
  >;
  team: Nullable<Team>;
  network: Nullable<Network>;
  impersonating: Nullable<Team>;
  broadcasters: Team[];
}

export type InertiaSharedProps<T = {}> = T & {
  jetstream: {
    canCreateTeams: boolean;
    canManageTwoFactorAuthentication: boolean;
    canUpdatePassword: boolean;
    canUpdateProfileInformation: boolean;
    flash: any;
    hasAccountDeletionFeatures: boolean;
    hasApiFeatures: boolean;
    hasTeamFeatures: boolean;
    hasTermsAndPrivacyPolicyFeature: boolean;
    managesProfilePhotos: boolean;
    hasEmailVerification: boolean;
  };
  auth: Auth;
  errorBags: any;
  errors: any;
  status?: string;
  features: {
    [key in EFeature]: boolean;
  };
};

export interface Session {
  id: number;
  ip_address: string;
  is_current_device: boolean;
  agent: {
    is_desktop: boolean;
    platform: string;
    browser: string;
  };
  last_active: DateTime;
}

export interface ApiToken extends BaseModel {
  name: string;
  abilities: string[];
  last_used_ago: Nullable<DateTime>;
}

export interface JetstreamTeamPermissions {
  canAddTeamMembers: boolean;
  canDeleteTeam: boolean;
  canRemoveTeamMembers: boolean;
  canUpdateTeam: boolean;
}

export interface Role {
  key: string;
  name: string;
  permissions: string[];
  description: string;
}

export interface TeamInvitation extends BaseModel {
  team_id: number;
  email: string;
  role: Nullable<string>;
  team?: Team;
}

export interface NetworkInvitation extends BaseModel {
  network_id: number;
  email: string;
  role: Nullable<string>;
  network?: Network;
}
