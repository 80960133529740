import './bootstrap';
import '../css/app.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { RouteContext } from '@/Hooks/useRoute';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { LaravelReactI18nProvider } from 'laravel-react-i18n';
import { ThemeProvider } from './Components/ThemeProvider';
import * as Sentry from '@sentry/react';
import { TooltipProvider } from "./Components/ui/tooltip";
import { Toaster } from "./Components/ui/sonner";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
});

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
  title: title => `${title} - ${appName}`,
  progress: {
    color: '#4B5563',
  },
  resolve: name => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
  setup({ el, App, props }) {
    const root = createRoot(el);
    return root.render(
      <RouteContext.Provider value={(window as any).route}>
        <LaravelReactI18nProvider files={import.meta.glob('/lang/*.json')}>
          <ThemeProvider defaultTheme="system" storageKey="vite-ui-theme">
            <Toaster position="top-center" />
            <TooltipProvider>
              <App {...props} />
            </TooltipProvider>
          </ThemeProvider>
        </LaravelReactI18nProvider>
      </RouteContext.Provider>,
    );
  },
});
